<template>
  <perfect-scrollbar class="bg-white" style="max-height: 100%" infinite-wrapper>
    <div
      class="main-section card border-right h-100 border-top d-flex flex-column"
      v-if="posts.length > 0"
    >
      <PostCard v-for="post in posts" :key="post.message_id" :post="post" />
      <mega-loading
        @infinite="loadMore($event)"
        :forceUseInfiniteWrapper="true"
        :identifier="last_message_id"
      />
    </div>
    <div v-else class="d-flex justify-content-center align-items-center h-100">
      <!-- <span>{{ $t("no_posts") }}</span> -->
      <!-- <mega-loading v-if="true" /> -->
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapActions } from "vuex";
import PostCard from "../components/post-card";
export default {
  name: "MainSection",
  components: {
    PostCard
  },
  methods: {
    ...mapActions([
      "fetchPosts",
      "fetchMorePosts",
      "fetchCategoryPosts",
      "fetchMoreCategoryPosts"
    ]),
    async loadMore($event) {
      const channel_id = this.$store.state.channels.selectedChannel;

      let result;
      if (this.$route.query.id) {
        result = await this.fetchMoreCategoryPosts({
          category_id: this.$route.query.id,
          offset: this.$store.state.channels.posts.length
        });
      } else {
        result = await this.fetchMorePosts({
          message_id: this.last_message_id,
          channel_id
        });
      }

      console.log(result, "🎁🎁🎁");

      if (result == "no_posts") {
        $event.complete();
      }
    }
  },
  computed: {
    posts() {
      return this.$store.state.channels.posts;
    },
    last_message_id() {
      return this.$store.state.channels.posts[
        this.$store.state.channels.posts.length - 1
      ].message_id;
    }
  },
  mounted() {
    if (this.$route.query.id) {
      this.fetchCategoryPosts(this.$route.query.id);
    } else {
      this.fetchPosts();
    }
  }
};
</script>

<style lang="sass" scoped>
.main-section
  height: 100%
</style>
