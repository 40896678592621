<template>
  <main class="models-page">
    <section
      class="border-bottom bg-lighter md-up:sticky-top"
      style="top: 30px;"
    >
      <div class="container py-2">
        <nav class="nav sm-down:flex-column">
          <span class="btn btn-sm btn-primary mr-2">
            <IconBase :viewBox="'0 0 22 22'" :width="14"><Channels /></IconBase>
            Посты
          </span>
          <router-link
            to="/categories/channels"
            class="btn btn-sm btn-light text-left d-block mr-2"
          >
            <i class="i-folder float-left"></i>
            <span>{{ $t("categories") }}</span>
          </router-link>
          <div
            v-if="$route.query.ruCategory"
            style="flex-grow: 1; text-align: center;"
          >
            {{ $t("selected_category") }} "{{
              $i18n.locale == "en"
                ? $route.query.enCategory
                : $route.query.ruCategory
            }}"
            <mega-button @click="clearCategory">
              <div style="transform: rotateZ(45deg);">
                <i class="i-plus"></i>
              </div>
            </mega-button>
          </div>
          <!-- <span
            class="btn btn-sm btn-light mr-2"
            @click="controls_expanded = !controls_expanded"
          >
            <i class="i-filter mr-1" />
            <span> {{ $t("filters") }} </span>
            <i
              class="i-chevron-down small control-btn"
              :class="{ inverted: controls_expanded }"
            />
          </span> -->
        </nav>
        <!-- <Controls
          class="controls"
          :class="{ controls_active: controls_expanded }"
        /> -->
      </div>
    </section>
    <div class="row channels" id="channels">
      <side-menu class="col-3 p-0 p-sticky" />
      <main-section class="col-9 p-0" />
    </div>
  </main>
</template>

<script>
import { mapActions } from "vuex";
import SideMenu from "./sections/side-menu.vue";
import MainSection from "./sections/main-section.vue";
import IconBase from "../../../components/icons/IconBase.vue";
//import Download from "../../../components/icons/set/Download.vue";
//import Delete from "../../../components/icons/set/Delete.vue";
//import Promoted from "../../../components/icons/set/Promoted.vue";
import Channels from "../../../components/icons/set/Channels.vue";
//import Controls from "./sections/controls.vue";

export default {
  name: "ChannelsPage",
  components: {
    IconBase,
    //Download,
    //Delete,
    //Promoted,
    Channels,
    SideMenu,
    MainSection
    //Controls
  },
  data() {
    return {
      controls_expanded: false
    };
  },
  methods: {
    ...mapActions(["fetchPosts"]),
    clearCategory() {
      this.$router.replace({ query: null });
      this.$store.state.channels.selectedChannel == 0
        ? this.fetchPosts()
        : this.fetchPosts({
            channel_id: this.$store.state.channels.selectedChannel
          });
    }
  },
  mounted() {
    this.$navbar.name = this.$t("channels");
  },
  beforeDestroy() {
    this.$store.commit("clearPosts");
    this.$store.commit("clearChannels");
  }
};
</script>

<style lang="sass" scoped>
.channels
  height: calc(100% - 47px)
.models-page
  height: 100%
.control-btn
  transition: transform 200ms
.inverted
  transform: rotateZ(-180deg)
.controls
  max-height: 0px
  margin-top: 0px
  transition: max-height 200ms linear 100ms, opacity 200ms linear 0ms, margin-top 200ms linear 100ms
  opacity: 0
  visibility: hidden
  &_active
    margin-top: 10px
    max-height: 110px
    opacity: 1
    transition: max-height 200ms linear 0ms, opacity 200ms linear 100ms, margin-top 200ms linear 100ms
    visibility: visible
</style>
