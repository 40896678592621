import { render, staticRenderFns } from "./main-section.vue?vue&type=template&id=ce7c91e6&scoped=true&"
import script from "./main-section.vue?vue&type=script&lang=js&"
export * from "./main-section.vue?vue&type=script&lang=js&"
import style0 from "./main-section.vue?vue&type=style&index=0&id=ce7c91e6&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ce7c91e6",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('ce7c91e6')) {
      api.createRecord('ce7c91e6', component.options)
    } else {
      api.reload('ce7c91e6', component.options)
    }
    module.hot.accept("./main-section.vue?vue&type=template&id=ce7c91e6&scoped=true&", function () {
      api.rerender('ce7c91e6', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/templates/pages/Channels/sections/main-section.vue"
export default component.exports