var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex mx-2 my-1 card row post rounded mb-0",
      class: {
        post_active: _vm.recommended || _vm.category,
        post_selected: _vm.selected,
        post_expanded: _vm.expanded
      }
    },
    [
      _c(
        "div",
        {
          staticClass:
            "col-8 post__content d-flex justify-content-between align-items-center pr-0"
        },
        [
          _c(
            "div",
            { staticClass: "p-relative" },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selected,
                    expression: "selected"
                  }
                ],
                staticClass: "p-absolute",
                staticStyle: {
                  top: "-2px",
                  left: "-7px",
                  width: "1rem",
                  height: "1rem",
                  "z-index": "20"
                },
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.selected)
                    ? _vm._i(_vm.selected, null) > -1
                    : _vm.selected
                },
                on: {
                  click: function($event) {
                    return _vm.selectPost(_vm.post.message_id)
                  },
                  change: function($event) {
                    var $$a = _vm.selected,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 && (_vm.selected = $$a.concat([$$v]))
                      } else {
                        $$i > -1 &&
                          (_vm.selected = $$a
                            .slice(0, $$i)
                            .concat($$a.slice($$i + 1)))
                      }
                    } else {
                      _vm.selected = $$c
                    }
                  }
                }
              }),
              _vm._v(" "),
              _c("mega-image", {
                staticClass: "post__channel-image mr-2",
                attrs: { ratio: "1x1", src: _vm.post.avatar, alt: "" }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex post__text flex-column px-2 text-overflow py-2 w-100"
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex justify-content-around post__head w-100"
                },
                [
                  _c("div", { staticClass: "d-flex align-items-start" }, [
                    _c(
                      "h4",
                      {
                        staticClass: "post__header text-overflow",
                        staticStyle: { "max-width": "10rem" }
                      },
                      [
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.post.name) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "post__time mx-1 small text-muted" },
                      [
                        _c("i", { staticClass: "i-calendar" }),
                        _vm._v(
                          "\n            " +
                            _vm._s(new Date(_vm.post.created_at).getDate()) +
                            "-" +
                            _vm._s(
                              new Date(_vm.post.created_at).getMonth() + 1
                            ) +
                            "-" +
                            _vm._s(
                              new Date(_vm.post.created_at).getFullYear()
                            ) +
                            "\n          "
                        )
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "post__followers small text-muted" },
                      [
                        _c("i", { staticClass: "i-user" }),
                        _vm._v(
                          " " +
                            _vm._s(_vm.post.subscriber_count) +
                            "\n          "
                        )
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("div", { staticStyle: { "flex-grow": "1" } }),
                  _vm._v(" "),
                  _vm._m(0)
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "post__text-content text-overflow text-muted align-self-start small",
                  class: { "post__text-content_expanded": _vm.expanded }
                },
                [
                  _vm._v("\n        " + _vm._s(_vm.post.text) + "\n        "),
                  _vm.post.image && !_vm.expanded
                    ? _c("span", [_vm._v("(Вложение)")])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.post.video && _vm.expanded
                    ? _c("video", {
                        staticClass: "post__image_expanded",
                        attrs: {
                          controls: "",
                          src: _vm.post.video.url,
                          alt: ""
                        }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.post.image && _vm.expanded
                    ? _c(
                        "viewer",
                        {
                          attrs: {
                            images: [_vm.post.image.url],
                            options: {
                              navbar: false,
                              title: false,
                              toolbar: false
                            }
                          }
                        },
                        [
                          _c("img", {
                            staticClass: "post__image_expanded",
                            attrs: { src: _vm.post.image.url, alt: "" }
                          })
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _vm._v(" "),
          _c("div", { staticStyle: { "flex-grow": "1" } }),
          _vm._v(" "),
          _vm.post.video && !_vm.expanded
            ? _c("video", {
                staticClass: "post__image",
                attrs: { src: _vm.post.video.url, alt: "" }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.post.image && !_vm.expanded
            ? _c(
                "viewer",
                {
                  attrs: {
                    images: [_vm.post.image.url],
                    options: { navbar: false, title: false, toolbar: false }
                  }
                },
                [
                  _c("img", {
                    staticClass: "post__image",
                    attrs: { src: _vm.post.image.url, alt: "" }
                  })
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "col-4 post__controls d-flex pr-0" }, [
        _c(
          "div",
          {
            staticClass:
              "post__inputs d-flex flex-column justify-content-center h-100"
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "post__input-wrapper p-0 m-0 d-flex align-items-center"
              },
              [
                _c("i", { staticClass: "i-eye mr-1" }),
                _vm._v(" "),
                _c("span", { staticClass: "mr-1 small" }, [
                  _vm._v(" " + _vm._s(_vm.post.view_count) + " ")
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "flex-grow": "1" } }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.views,
                      expression: "views"
                    }
                  ],
                  staticClass: "post__input",
                  attrs: { type: "number" },
                  domProps: { value: _vm.views },
                  on: {
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.cheatValues("views", $event)
                    },
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.views = $event.target.value
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "post__input-wrapper d-flex p-0 m-0 align-items-center"
              },
              [
                _c("i", { staticClass: "i-heart mr-1" }),
                _vm._v(" "),
                _c("span", { staticClass: "mr-1 small" }, [
                  _vm._v(_vm._s(_vm.compLikes))
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "flex-grow": "1" } }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.likes,
                      expression: "likes"
                    }
                  ],
                  staticClass: "post__input",
                  attrs: { type: "number" },
                  domProps: { value: _vm.likes },
                  on: {
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.cheatValues("likes", $event)
                    },
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.likes = $event.target.value
                    }
                  }
                })
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "post__input-wrapper d-flex p-0 m-0 align-items-center"
              },
              [
                _c("i", { staticClass: "i-share mr-1" }),
                _vm._v(" "),
                _c("span", { staticClass: "mr-1 small" }, [
                  _vm._v(_vm._s(_vm.post.fwd_count))
                ]),
                _vm._v(" "),
                _c("div", { staticStyle: { "flex-grow": "1" } }),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shares,
                      expression: "shares"
                    }
                  ],
                  staticClass: "post__input",
                  attrs: { type: "text" },
                  domProps: { value: _vm.shares },
                  on: {
                    keypress: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      )
                        return null
                      return _vm.cheatValues("shares", $event)
                    },
                    input: function($event) {
                      if ($event.target.composing) return
                      _vm.shares = $event.target.value
                    }
                  }
                })
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "post_checboxes d-flex flex-column justify-content-center ml-2"
          },
          [
            _vm.post.category
              ? _c(
                  "div",
                  {
                    staticClass: "category small",
                    staticStyle: { height: "24px" }
                  },
                  [
                    _c("span", { staticClass: "small" }, [
                      _vm._v(
                        _vm._s(
                          _vm.post.category ? _vm.post.category.label_ru : ""
                        )
                      )
                    ]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "small",
                      staticStyle: { "max-height": "15px" },
                      attrs: { src: _vm.post.category.icon, alt: "" }
                    })
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "post__checkbox-wrapper d-flex align-items-center"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.post.in_category,
                      expression: "post.in_category"
                    }
                  ],
                  staticStyle: { width: "1.2rem", height: "1.2rem" },
                  attrs: { type: "checkbox", disabled: !_vm.post.category },
                  domProps: {
                    checked: Array.isArray(_vm.post.in_category)
                      ? _vm._i(_vm.post.in_category, null) > -1
                      : _vm.post.in_category
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.post.in_category,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.post,
                                "in_category",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.post,
                                "in_category",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.post, "in_category", $$c)
                        }
                      },
                      _vm.categoryChange
                    ]
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml-1 small" }, [
                  _vm._v("\n          В ленту категорий\n        ")
                ])
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "post__checkbox-wrapper d-flex align-items-center"
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.post.in_popular,
                      expression: "post.in_popular"
                    }
                  ],
                  staticStyle: { width: "1.2rem", height: "1.2rem" },
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.post.in_popular)
                      ? _vm._i(_vm.post.in_popular, null) > -1
                      : _vm.post.in_popular
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$a = _vm.post.in_popular,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.post,
                                "in_popular",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.post,
                                "in_popular",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.post, "in_popular", $$c)
                        }
                      },
                      _vm.popularChange
                    ]
                  }
                }),
                _vm._v(" "),
                _c("span", { staticClass: "ml-1 small" }, [
                  _vm._v(" Рекомендуем ")
                ])
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticStyle: { "flex-grow": "1" } }),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "post__side-controls border-left d-flex flex-column m-0 p-0 justify-content-around align-items-center px-1"
          },
          [
            _c("i", { staticClass: "i-share cursor-pointer" }),
            _vm._v(" "),
            _c("i", {
              staticClass: "i-chevron-down cursor-pointer chevron",
              class: { chevron__rotated: _vm.expanded },
              on: {
                click: function($event) {
                  _vm.expanded = !_vm.expanded
                }
              }
            })
          ]
        )
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-items-start" }, [
      _c("div", { staticClass: "post__posted-time small text-muted" }, [
        _vm._v("\n            02.02.2020, 04:20\n          ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }