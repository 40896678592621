var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex pl-3 cursor-pointer card mb-0 pt-2",
      class: {
        active: _vm.channel.id == _vm.$store.state.channels.selectedChannel
      },
      staticStyle: { "align-items": "center" },
      on: { click: _vm.channelClicked }
    },
    [
      _c("mega-image", {
        staticClass: "card__image",
        attrs: { ratio: "1x1", src: _vm.channel.avatar }
      }),
      _vm._v(" "),
      _c("div", { staticClass: "card__data px-3 text-overflow" }, [
        _c("div", { staticClass: "card__header text-overflow" }, [
          _vm._v("\n      " + _vm._s(_vm.channel.name) + "\n      "),
          _c("div", { staticStyle: { "flex-grow": "1" } }),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "card__invite",
              attrs: { href: "", title: _vm.$t("invite_link") },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.copyLink.apply(null, arguments)
                }
              }
            },
            [_c("i", { staticClass: "i-copy" })]
          )
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "card__followers small text-overflow",
            staticStyle: { "flex-wrap": "wrap" }
          },
          [
            _c("span", [
              _c("i", { staticClass: "i-user" }),
              _vm._v(
                " Участников: " + _vm._s(_vm.channel.subscriber_count) + " "
              )
            ]),
            _c("br"),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.participants,
                  expression: "participants"
                }
              ],
              staticClass: "post__input",
              attrs: { type: "text", placeholder: "Участников" },
              domProps: { value: _vm.participants },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                },
                keypress: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.cheatValues("participants", $event)
                },
                input: function($event) {
                  if ($event.target.composing) return
                  _vm.participants = $event.target.value
                }
              }
            })
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "card__date small text-overflow" }, [
          _c("i", { staticClass: "i-calendar" }),
          _vm._v(" Дата создания: " + _vm._s(_vm.channel_created) + "\n    ")
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }