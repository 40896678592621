var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "perfect-scrollbar",
    {
      staticClass: "bg-white",
      staticStyle: { "max-height": "100%" },
      attrs: { "infinite-wrapper": "" }
    },
    [
      _vm.posts.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "main-section card border-right h-100 border-top d-flex flex-column"
            },
            [
              _vm._l(_vm.posts, function(post) {
                return _c("PostCard", {
                  key: post.message_id,
                  attrs: { post: post }
                })
              }),
              _vm._v(" "),
              _c("mega-loading", {
                attrs: {
                  forceUseInfiniteWrapper: true,
                  identifier: _vm.last_message_id
                },
                on: {
                  infinite: function($event) {
                    return _vm.loadMore($event)
                  }
                }
              })
            ],
            2
          )
        : _c("div", {
            staticClass:
              "d-flex justify-content-center align-items-center h-100"
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }