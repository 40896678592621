var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("perfect-scrollbar", { staticStyle: { "max-height": "100%" } }, [
    _c(
      "div",
      {
        staticClass:
          "card border-right bg-white h-100 border-top d-flex flex-column"
      },
      [
        _c(
          "div",
          {
            staticClass:
              "channels__header d-flex flex-column border-bottom pb-2 p-sticky"
          },
          [
            _c("h3", { staticClass: "pt-1 pl-3 mb-0" }, [
              _vm._v("\n        " + _vm._s(_vm.$t("channels")) + "\n        "),
              _c("span", { staticClass: "small" }, [
                _vm._v(
                  "\n          (" +
                    _vm._s(_vm.$store.state.channels.channels.length) +
                    ")\n        "
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "search d-flex flex-column px-3" }, [
              _c(
                "label",
                { staticClass: "small", attrs: { for: "channels__search" } },
                [_vm._v("Поиск по каналам:")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "p-relative" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.search,
                      expression: "search"
                    }
                  ],
                  staticClass: "channels__search w-100",
                  attrs: {
                    type: "search",
                    label: "asdf",
                    id: "channels__search"
                  },
                  domProps: { value: _vm.search },
                  on: {
                    input: [
                      function($event) {
                        if ($event.target.composing) return
                        _vm.search = $event.target.value
                      },
                      _vm.searchEvent
                    ]
                  }
                }),
                _c("i", {
                  staticClass: "i-search p-absolute",
                  staticStyle: { left: "3px", top: "7px" }
                })
              ])
            ])
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "channels" }, [
          _vm.channels.length > 0
            ? _c(
                "div",
                _vm._l(_vm.channels, function(channel) {
                  return _c("ChannelCard", {
                    key: channel.id,
                    attrs: { channel: channel }
                  })
                }),
                1
              )
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center"
                },
                [_c("span", [_vm._v(_vm._s(_vm.$t("no_channels")))])]
              )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }