<template>
  <div
    class="d-flex pl-3 cursor-pointer card mb-0 pt-2"
    @click="channelClicked"
    :class="{ active: channel.id == $store.state.channels.selectedChannel }"
    style="align-items: center;"
  >
    <mega-image ratio="1x1" class="card__image" :src="channel.avatar" />
    <div class="card__data px-3 text-overflow">
      <div class="card__header text-overflow">
        {{ channel.name }}
        <div style="flex-grow: 1"></div>
        <a
          href=""
          :title="$t('invite_link')"
          class="card__invite"
          @click.prevent.stop="copyLink"
        >
          <i class="i-copy"></i>
        </a>
      </div>
      <div class="card__followers small text-overflow" style="flex-wrap: wrap;">
        <span>
          <i class="i-user" /> Участников: {{ channel.subscriber_count }} </span
        ><br />
        <input
          type="text"
          class="post__input"
          v-model="participants"
          placeholder="Участников"
          @click.prevent.stop
          @keypress.enter="cheatValues('participants', $event)"
        />
      </div>
      <div class="card__date small text-overflow">
        <i class="i-calendar" /> Дата создания: {{ channel_created }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "ChannelCard",
  data() {
    return {
      participants: ""
    };
  },
  props: {
    channel: {
      type: Object
    },
    post: {
      type: Object
    }
  },
  computed: {
    channel_created() {
      return (
        new Date(this.channel.created_at).getDate().toString() +
        "-" +
        new Date(this.channel.created_at).getMonth().toString() +
        "-" +
        new Date(this.channel.created_at).getFullYear().toString()
      );
    }
  },
  methods: {
    ...mapActions(["fetchPosts"]),
    cheatValues(type, event) {
      switch (type) {
        case "participants":
          this.$api.msg
            .put("/admin/channel/subscriber/count", {
              channel_id: Number(this.channel.id),
              subscriber_count: Number(event.target.value),
              sn_id: this.$store.getters.selectedSnId
            })
            .then(res => {
              this.participants = "";
              this.channel.subscriber_count = res.data.subscriber_count;
              this.$alert.success(this.$t("success"));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });
          break;
      }
    },
    channelClicked() {
      this.$router.replace({ query: null });
      this.$store.commit("selectChannel", this.channel.id);
      this.$store.state.channels.selectedChannel == 0
        ? this.fetchPosts()
        : this.fetchPosts({
            channel_id: this.$store.state.channels.selectedChannel
          });
    },
    copyLink() {
      let text = document.createElement("textarea");
      document.body.appendChild(text);
      text.textContent = `https://arround.world/channel/join/${this.channel.invite_code}`;
      text.focus();
      text.select();
      document.execCommand("copy");
      this.$alert.success(this.$t("copied"));
      document.body.removeChild(text);
    }
  }
};
</script>

<style lang="sass" scoped>
.active
  background: #e3edf5
.card
  height: auto
  border-bottom: 1px solid rgba(0,0,0, 0.1)
  padding-bottom: 0.5rem
  &__image
    min-width: 4rem
    border-radius: 0.7rem
    max-height: 4rem
  &__data
    width: 100%
    display: flex
    justify-content: center
    flex-direction: column
    align-items: flex-start
  &__header
    width: 100%
    display: flex
    text-overflow: ellipsis
    font-weight: 700
    line-height: 1.2rem
  &__invite
    color: #8f969f
</style>
