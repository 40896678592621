var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("main", { staticClass: "models-page" }, [
    _c(
      "section",
      {
        staticClass: "border-bottom bg-lighter md-up:sticky-top",
        staticStyle: { top: "30px" }
      },
      [
        _c("div", { staticClass: "container py-2" }, [
          _c(
            "nav",
            { staticClass: "nav sm-down:flex-column" },
            [
              _c(
                "span",
                { staticClass: "btn btn-sm btn-primary mr-2" },
                [
                  _c(
                    "IconBase",
                    { attrs: { viewBox: "0 0 22 22", width: 14 } },
                    [_c("Channels")],
                    1
                  ),
                  _vm._v("\n          Посты\n        ")
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "router-link",
                {
                  staticClass: "btn btn-sm btn-light text-left d-block mr-2",
                  attrs: { to: "/categories/channels" }
                },
                [
                  _c("i", { staticClass: "i-folder float-left" }),
                  _vm._v(" "),
                  _c("span", [_vm._v(_vm._s(_vm.$t("categories")))])
                ]
              ),
              _vm._v(" "),
              _vm.$route.query.ruCategory
                ? _c(
                    "div",
                    {
                      staticStyle: { "flex-grow": "1", "text-align": "center" }
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("selected_category")) +
                          ' "' +
                          _vm._s(
                            _vm.$i18n.locale == "en"
                              ? _vm.$route.query.enCategory
                              : _vm.$route.query.ruCategory
                          ) +
                          '"\n          '
                      ),
                      _c("mega-button", { on: { click: _vm.clearCategory } }, [
                        _c(
                          "div",
                          { staticStyle: { transform: "rotateZ(45deg)" } },
                          [_c("i", { staticClass: "i-plus" })]
                        )
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ])
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row channels", attrs: { id: "channels" } },
      [
        _c("side-menu", { staticClass: "col-3 p-0 p-sticky" }),
        _vm._v(" "),
        _c("main-section", { staticClass: "col-9 p-0" })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }