<template>
  <perfect-scrollbar style="max-height: 100%">
    <div class="card border-right bg-white h-100 border-top d-flex flex-column">
      <div
        class="channels__header d-flex flex-column border-bottom pb-2 p-sticky"
      >
        <h3 class="pt-1 pl-3 mb-0">
          {{ $t("channels") }}
          <span class="small">
            ({{ $store.state.channels.channels.length }})
          </span>
        </h3>
        <div class="search d-flex flex-column px-3">
          <label for="channels__search" class="small">Поиск по каналам:</label>
          <div class="p-relative">
            <input
              type="search"
              class="channels__search w-100"
              label="asdf"
              id="channels__search"
              v-model="search"
              @input="searchEvent"
            /><i class="i-search p-absolute" style="left: 3px; top: 7px" />
            <!-- <i
              class="i-close p-absolute cursor-pointer"
              style="right: 3px; top: 8px"
              v-show="search"
              @click="search = ''"
            /> -->
          </div>
        </div>
      </div>
      <div class="channels">
        <div v-if="channels.length > 0">
          <ChannelCard
            v-for="channel in channels"
            :key="channel.id"
            :channel="channel"
          />
        </div>
        <div v-else class="d-flex justify-content-center align-items-center">
          <span>{{ $t("no_channels") }}</span>
        </div>
      </div>
    </div>
  </perfect-scrollbar>
</template>

<script>
import { mapActions, mapState } from "vuex";
import ChannelCard from "../components/channel-card";
export default {
  name: "SideMenu",
  components: {
    ChannelCard
  },
  computed: {
    ...mapState({
      channels: state => state.channels.channels
    })
  },
  watch: {},
  methods: {
    ...mapActions(["fetchChannels"]),
    searchEvent(event) {
      this.fetchChannels(this.search);
    }
  },
  mounted() {
    this.fetchChannels();
  },
  data() {
    return {
      search: ""
    };
  }
};
</script>

<style lang="sass" scoped>
.channels__search
  padding-left: 19px
</style>
