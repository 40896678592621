<template>
  <div
    class="d-flex mx-2 my-1 card row post rounded mb-0"
    :class="{
      post_active: recommended || category,
      post_selected: selected,
      post_expanded: expanded
    }"
  >
    <div
      class="col-8 post__content d-flex justify-content-between align-items-center pr-0"
    >
      <div class="p-relative">
        <input
          type="checkbox"
          class="p-absolute"
          style="top: -2px; left: -7px; width: 1rem; height: 1rem; z-index: 20"
          v-model="selected"
          @click="selectPost(post.message_id)"
        />
        <mega-image
          ratio="1x1"
          :src="post.avatar"
          class="post__channel-image mr-2"
          alt=""
        />
      </div>
      <div class="d-flex post__text flex-column px-2 text-overflow py-2 w-100">
        <div class="d-flex justify-content-around post__head w-100">
          <div class="d-flex align-items-start">
            <h4 class="post__header text-overflow" style="max-width: 10rem">
              {{ post.name }}
            </h4>
            <div class="post__time mx-1 small text-muted">
              <i class="i-calendar"></i>
              {{ new Date(post.created_at).getDate() }}-{{
                new Date(post.created_at).getMonth() + 1
              }}-{{ new Date(post.created_at).getFullYear() }}
            </div>
            <div class="post__followers small text-muted">
              <i class="i-user"></i> {{ post.subscriber_count }}
            </div>
          </div>
          <div style="flex-grow: 1"></div>
          <div class="d-flex align-items-start">
            <div class="post__posted-time small text-muted">
              02.02.2020, 04:20
            </div>
          </div>
        </div>
        <div
          class="post__text-content text-overflow text-muted align-self-start small"
          :class="{ 'post__text-content_expanded': expanded }"
        >
          {{ post.text }}
          <span v-if="post.image && !expanded">(Вложение)</span>
          <video
            controls
            v-if="post.video && expanded"
            :src="post.video.url"
            class="post__image_expanded"
            alt=""
          />
          <viewer
            v-if="post.image && expanded"
            :images="[post.image.url]"
            :options="{ navbar: false, title: false, toolbar: false }"
          >
            <img :src="post.image.url" class="post__image_expanded" alt="" />
          </viewer>
        </div>
      </div>
      <div style="flex-grow: 1" />
      <video
        v-if="post.video && !expanded"
        :src="post.video.url"
        class="post__image"
        alt=""
      />
      <viewer
        v-if="post.image && !expanded"
        :images="[post.image.url]"
        :options="{ navbar: false, title: false, toolbar: false }"
      >
        <img :src="post.image.url" class="post__image" alt="" />
      </viewer>
    </div>
    <div class="col-4 post__controls d-flex pr-0">
      <div class="post__inputs d-flex flex-column justify-content-center h-100">
        <div class="post__input-wrapper p-0 m-0 d-flex align-items-center">
          <i class="i-eye mr-1" />
          <span class="mr-1 small"> {{ post.view_count }} </span>
          <div style="flex-grow: 1"></div>
          <input
            type="number"
            class="post__input"
            v-model="views"
            @keypress.enter="cheatValues('views', $event)"
          />
        </div>
        <div class="post__input-wrapper d-flex p-0 m-0 align-items-center">
          <i class="i-heart mr-1" />
          <span class="mr-1 small">{{ compLikes }}</span>
          <div style="flex-grow: 1"></div>
          <input
            type="number"
            class="post__input"
            v-model="likes"
            @keypress.enter="cheatValues('likes', $event)"
          />
        </div>
        <div class="post__input-wrapper d-flex p-0 m-0 align-items-center">
          <i class="i-share mr-1" />
          <span class="mr-1 small">{{ post.fwd_count }}</span>
          <div style="flex-grow: 1"></div>
          <input
            type="text"
            class="post__input"
            v-model="shares"
            @keypress.enter="cheatValues('shares', $event)"
          />
        </div>
      </div>
      <div
        class="post_checboxes d-flex flex-column justify-content-center ml-2"
      >
        <div class="category small" v-if="post.category" style="height: 24px">
          <span class="small">{{
            post.category ? post.category.label_ru : ""
          }}</span>
          <img
            :src="post.category.icon"
            class="small"
            alt=""
            style="max-height: 15px"
          />
        </div>
        <div class="post__checkbox-wrapper d-flex align-items-center">
          <input
            type="checkbox"
            style="width: 1.2rem; height: 1.2rem"
            @change="categoryChange"
            :disabled="!post.category"
            v-model="post.in_category"
          />
          <span class="ml-1 small">
            В ленту категорий
          </span>
        </div>
        <div class="post__checkbox-wrapper d-flex align-items-center">
          <input
            type="checkbox"
            style="width: 1.2rem; height: 1.2rem"
            v-model="post.in_popular"
            @change="popularChange"
          />
          <span class="ml-1 small"> Рекомендуем </span>
        </div>
      </div>
      <div style="flex-grow: 1" />
      <div
        class="post__side-controls border-left d-flex flex-column m-0 p-0 justify-content-around align-items-center px-1"
      >
        <!-- <i class="i-more-horizontal cursor-pointer" /> -->
        <i class="i-share cursor-pointer" />
        <i
          class="i-chevron-down cursor-pointer chevron"
          :class="{ chevron__rotated: expanded }"
          @click="expanded = !expanded"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  name: "PostCard",
  props: {
    post: {
      type: Object
    }
  },
  computed: {
    compLikes() {
      if (this.post.like_count > 1000) {
        return this.post.like_count / 1000 + "K";
      }
      return this.post.like_count;
    },
    compShares() {
      if (this.post.fwd_count > 1000) {
        return this.post.fwd_count / 1000 + "K";
      }
      return this.post.fwd_count;
    },
    compViews() {
      if (this.post.view_count > 1000) {
        return this.post.view_count / 1000 + "K";
      }
      return this.post.view_count;
    }
  },
  methods: {
    ...mapMutations(["selectPost"]),
    categoryChange() {
      if (!this.post.in_category) {
        this.$api.msg
          .delete("admin/explore/category", {
            params: {
              message_id: this.post.message_id
            }
          })
          .then(() => {
            this.category = !this.category;
            this.$alert.success(this.$t("success"));
          })
          .catch(() => {
            this.$alert.danger("err_server");
          });
      } else {
        this.$api.msg
          .post("admin/explore/category", {
            message_id: this.post.message_id
          })
          .then(() => {
            this.category = !this.category;
            this.$alert.success(this.$t("success"));
          })
          .catch(() => {
            this.$alert.danger("err_server");
          });
      }
    },
    popularChange() {
      if (!this.post.in_popular) {
        this.$api.msg
          .delete("admin/explore/popular", {
            params: {
              message_id: this.post.message_id,
              sn_id: this.$store.getters.selectedSnId
            }
          })
          .then(() => {
            this.recommended = !this.recommended;
            this.$alert.success(this.$t("success"));
          })
          .catch(() => {
            this.$alert.danger("err_server");
          });
      } else {
        this.$api.msg
          .post("admin/explore/popular", {
            message_id: this.post.message_id,
            sn_id: this.$store.getters.selectedSnId
          })
          .then(() => {
            this.recommended = !this.recommended;
            this.$alert.success(this.$t("success"));
          })
          .catch(() => {
            this.$alert.danger("err_server");
          });
      }
    },
    cheatValues(type, event) {
      switch (type) {
        case "likes":
          this.$api.msg
            .put("/admin/post/like-count", {
              message_id: Number(this.post.message_id),
              like_count: Number(this.likes),
              sn_id: this.$store.getters.selectedSnId
            })
            .then(() => {
              this.likes = "";
              this.post.like_count = event.target.value;
              this.$alert.success(this.$t("success"));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });
          break;
        case "views":
          this.$api.msg
            .put("admin/post/view-count", {
              message_id: Number(this.post.message_id),
              view_count: Number(this.views),
              sn_id: this.$store.getters.selectedSnId
            })
            .then(() => {
              this.views = "";
              this.post.view_count = event.target.value;
              this.$alert.success(this.$t("success"));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });
          break;
        case "shares":
          this.$api.msg
            .put("/admin/post/fwd-count", {
              message_id: Number(this.post.message_id),
              fwd_count: Number(this.shares),
              sn_id: this.$store.getters.selectedSnId
            })
            .then(() => {
              this.shares = "";
              this.post.fwd_count = event.target.value;
              this.$alert.success(this.$t("success"));
            })
            .catch(() => {
              this.$alert.danger(this.$t("err_server"));
            });
          break;
      }
    }
  },
  data() {
    return {
      expanded: false,
      category: this.post.in_category,
      recommended: this.post.in_popular,
      selected: false,
      likes: "",
      views: "",
      shares: ""
    };
  }
};
</script>

<style lang="sass" scoped>
.chevron
  transition: transform 200ms
  transform: rotateZ(0deg)
  &__rotated
    transition: transform 200ms
    transform: rotateZ(180deg)
.post
  max-width: 100%
  background-color: #F1F1F1
  transition: all 200ms
  height: 10.5rem
  max-height: 5.5rem
  &_expanded
    transition: all 2000ms
    max-height: 200rem
    height: auto
  &_selected
    box-shadow: 0px 0px 0px 2px #028cff
  &_active
    background-color: #E3EDF5
  &__controls
    max-height: 100%
  &__channel-image
    min-width: 4rem
    max-height: 4rem
  &__image
    max-height: 5.5rem
    max-width: 4rem
    &__expanded
      width: 100%
      height: auto
      display: block
  &__header
    font-weight: bold
    line-height: 1.3rem
  &__head
    height: 1.3rem
  &__text-content
    -webkit-box-orient: vertical
    display: -webkit-box
    -webkit-line-clamp: 3
    overflow: hidden
    text-overflow: ellipsis
    white-space: normal
    line-height: 1rem
    &_expanded
      -webkit-line-clamp: unset !important
      -webkit-box-orient: horizontal
      opacity: 1
      display: flex
      flex-direction: column
  &__input
    width: 3rem
    height: 1.2rem
    border: 1px solid #000000
    background-color: #ffffff
    border-radius: 4px
  &__inputs
    min-width: 96px
</style>
