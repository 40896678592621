import { render, staticRenderFns } from "./channel-card.vue?vue&type=template&id=740b10ae&scoped=true&"
import script from "./channel-card.vue?vue&type=script&lang=js&"
export * from "./channel-card.vue?vue&type=script&lang=js&"
import style0 from "./channel-card.vue?vue&type=style&index=0&id=740b10ae&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "740b10ae",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/node/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('740b10ae')) {
      api.createRecord('740b10ae', component.options)
    } else {
      api.reload('740b10ae', component.options)
    }
    module.hot.accept("./channel-card.vue?vue&type=template&id=740b10ae&scoped=true&", function () {
      api.rerender('740b10ae', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/templates/pages/Channels/components/channel-card.vue"
export default component.exports